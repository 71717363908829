import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

const config = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_DATABASE_URL,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
};

export const app = initializeApp(config);
export const firebaseDB = getFirestore(app);
const storageRef = getStorage(app);

const imagesRefFB = ref(storageRef, "images");
const biudConnectRefFB = ref(storageRef, "biudConnect");
const filesRefFB = ref(storageRef, "files");
const filesSefazRefFB = ref(storageRef, "files/sefaz");
const filesReportsRefFB = ref(storageRef, "reports");
const videosRefFB = ref(storageRef, "video");
const esgQuestionsRefFB = ref(storageRef, "esg-sebrae");
const whatsappTemplateImage = {
  image: ref(storageRef, "whatsappTemplateFiles/image"),
  video: ref(storageRef, "whatsappTemplateFiles/video"),
  document: ref(storageRef, "whatsappTemplateFiles/document"),
};

export const getUrlBiudConnect = () => {
  const buidCollectorRef = ref(biudConnectRefFB, "biud-collector-install.exe");
  return getDownloadURL(buidCollectorRef);
};

export const getUrlImagesFB = async (imageName: string) => {
  const imageRef = ref(imagesRefFB, imageName);
  return await getDownloadURL(imageRef);
};

export const getReportDownloadUrl = async (fileName: string) => {
  const fileRef = ref(filesReportsRefFB, fileName);
  return await getDownloadURL(fileRef);
};

export const getFilesDownloadUrl = async (fileName: string) => {
  const fileRef = ref(filesRefFB, fileName);
  return await getDownloadURL(fileRef);
};

export const getFilesSefazDownloadUrl = async (fileName: string) => {
  const fileRef = ref(filesSefazRefFB, fileName);
  return await getDownloadURL(fileRef);
};

export const getVideosDownloadUrl = async (fileName: string) => {
  const videoRef = ref(videosRefFB, fileName);
  return await getDownloadURL(videoRef);
};

export const getEsgQuestionsDownloadUrl = async (fileName: string) => {
  const esgQuestionsRef = ref(esgQuestionsRefFB, fileName);
  return await getDownloadURL(esgQuestionsRef);
};

export const getWhatsappTemplateFile = async (fileName: any, type: "image" | "video" | "document") => {
  const templateImageRef = ref(whatsappTemplateImage[type], fileName);
  return await getDownloadURL(templateImageRef);
};

export const getFileUrl = async (filename: any) => {
  const file = ref(storageRef, filename);
  return await getDownloadURL(file);
};

export const sendImage = async (file: any, imageName: string) => {
  try {
    const imageRef = ref(imagesRefFB, imageName);
    await uploadBytes(imageRef, file);
  } catch (err) {
    console.error((err as Error).message);
  }
};

export const sendFile = async (file: any, fileName: string) => {
  try {
    const fileRef = ref(filesRefFB, fileName);
    await uploadBytes(fileRef, file);
  } catch (err) {
    console.log(err);
  }
};

export const sendFileSefaz = async (file: any, fileName: string) => {
  try {
    const fileRef = ref(filesSefazRefFB, fileName);
    await uploadBytes(fileRef, file);
  } catch (err) {
    console.log(err);
  }
};

export const sendWhatsappTemplateFile = async (file: any, fileName: string, type: "image" | "video" | "document") => {
  try {
    const templateImageRef = ref(whatsappTemplateImage[type], fileName);
    await uploadBytes(templateImageRef, file);
  } catch (err: any) {
    console.log(err.message);
  }
};
