import React, { createContext, useContext } from "react";
import { IGlobalData, IGlobalDataProviderProps } from "./types";
import { IPlanSubscription } from "@services/apis/biudPlans/types";
import usePlanSubscription from "@context/GlobalContext/hooks/usePlanSubscription";
import useCheckAccess from "@context/GlobalContext/hooks/useCheckAccess";

const GlobalContext = createContext({} as IGlobalData);

function GlobalDataProvider({ children }: IGlobalDataProviderProps) {
  const { dataPlanSubscribed, loadingDataPlanSubscribed, fetchPlanSubscription } = usePlanSubscription();
  const { accessData, loadingCheckAccess, checkAccess } = useCheckAccess();

  const contextValues: IGlobalData = {
    currentPlan: {
      data: dataPlanSubscribed,
      isLoading: loadingDataPlanSubscribed,
      fetch: fetchPlanSubscription,
    },
    checkAccess: {
      data: accessData,
      isLoading: loadingCheckAccess,
      fetch: checkAccess,
    },
  };

  return <GlobalContext.Provider value={contextValues}>{children}</GlobalContext.Provider>;
}

export const useGlobalData = (): IGlobalData => useContext(GlobalContext);

export type { IGlobalData, IGlobalDataProviderProps, IPlanSubscription };
export default GlobalDataProvider;
