import React from "react";
import InfoCircle from "../CustomIcons/Generics/InfoCircle";
import Youtube from "../CustomIcons/Generics/Youtube";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import { Link } from "react-router-dom";
import { TagAlt } from "@newcomponentes/CustomIcons";

export default function OptionCard({ description, icon = "helpCenter", callback, link, internal }: any) {
  const icons: any = {
    content: <Youtube style={{ width: 18, height: 18 }} />,
    helpCenter: <InfoCircle style={{ width: 18, height: 18 }} viewBox="0 0 16 16" />,
    networkCheck: <NetworkCheckIcon fontSize="small" />,
    update: <TagAlt style={{ fontSize: "16px" }} />,
  };

  return internal ? (
    <Link to={link} className="option-card">
      {icons[icon]}
      {description}
    </Link>
  ) : (
    <a
      href={link}
      className="option-card"
      target="_blank"
      rel="noreferrer noopener"
      onClick={() => {
        if (callback) callback();
      }}
    >
      {icons[icon]}
      {description}
    </a>
  );
}
