import React, { useState } from "react";
import "./styles.scss";
import Modal from "@components/Modal";
import { imgOfficeBuilding } from "@imgs";
import { useMutation } from "@apollo/client";
import BiudInput from "../../Inputs/BiudInput";
import { getBusinessByCnpj } from "@query/business";
import BiudMaskInput from "../../Inputs/BiudMaskInput";
import { Attention } from "@newcomponentes/CustomIcons";
import FailModalContent from "@newcomponentes/Modal/FailModalContent";
import { removerCaracteresEspeciais, validarCNPJ } from "@services/utils";
import { optInMessage, optOutMessage } from "@pages/BusinessRegister/utils";
import RegisterFormModalContainer from "@containers/RegisterFormModalContainer";
import SuccessModalContent from "components/NewComponentes/Modal/SuccessModalContent";
import {
  ADD_BUSINESS_GOODBYE_REASON_MUTATION,
  CREATE_BUSINES_PAGE_MUTATION,
  CREATE_BUSINESS_GOODBYE_PAGE_MUTATION,
  REGISTER_BUSINESS_MUTATION,
} from "@mutation/gql/business";
import { getCompany } from "@services/auth";

export default function Step3({
  loading,
  setLoading,
  setStep,
  setNewBusinessInfo,
  endOnboarding,
  groupId,
  animation,
  primaryBtnTitle,
  secondaryBtnTitle,
  openChooseBusinessGroupModal = () => "",
}: any) {
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [itemSelected, setItemSelected] = useState();
  const [operatingAreasSelected, setOperatingAreasSelected] = useState([] as any);

  const [formError, setFormError] = useState("");
  const [loadingCreateBusiness, setLoadingCreateBusiness] = useState(false);

  const [internalModalContent, setInternalModalContent] = useState(<></>);
  const [internalModalVisible, setInternalModalVisible] = useState(false);

  const [registerBusiness] = useMutation(REGISTER_BUSINESS_MUTATION);
  const [createBusinessPage] = useMutation(CREATE_BUSINES_PAGE_MUTATION);
  const [createBusinessGoodbyePage] = useMutation(CREATE_BUSINESS_GOODBYE_PAGE_MUTATION);
  const [addButtonBusinessGoodbyeReason] = useMutation(ADD_BUSINESS_GOODBYE_REASON_MUTATION);

  const saveDefaultButtons = async (pageId: any, businessId: any) => {
    const reasons = [
      { label: "Recebo muitos emails" },
      { label: "Não lembro de ter me cadastrado nessa lista" },
      { label: "Apenas quero sair" },
    ];

    try {
      const promises = reasons.map((reason) => {
        const data = {
          businessId,
          goodbyePageId: pageId,
          label: reason.label,
        };
        return addButtonBusinessGoodbyeReason({ variables: data, fetchPolicy: "no-cache" });
      });

      await Promise.all(promises);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  async function checkCNPJExists(cnpj: any) {
    try {
      setLoading(true);
      const result = await getBusinessByCnpj(cnpj);
      return result.Business.length > 0;
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const openSucessModal = () => {
    setInternalModalContent(
      <SuccessModalContent
        title="Empresa criada com sucesso!"
        buttonText="Finalizar"
        closeModal={() => {
          endOnboarding();
          openChooseBusinessGroupModal();
        }}
        onButtonClick={() => {
          openChooseBusinessGroupModal();
        }}
        autoMainBtnClick={false}
      />
    );
    setInternalModalVisible(true);
  };

  const openFailModal = () => {
    setInternalModalContent(
      <FailModalContent
        title="Ops, tivemos um problema inesperado!"
        text="Tente novamente mais tarde"
        closeModal={endOnboarding}
        mainBtnText="Fechar"
        mainBtnOnClick={endOnboarding}
      />
    );
    setInternalModalVisible(true);
  };

  const businessRegister = async () => {
    if (!name) {
      setFormError("Insira um nome para a unidade");
      return;
    }
    if (!cnpj) {
      setFormError("Informe o CNPJ");
      return;
    }

    const cnpjDigits = removerCaracteresEspeciais(cnpj);
    if (cnpjDigits.length !== 14) {
      setFormError("O CNPJ precisa ter 14 dígitos");
      return;
    }
    if (!validarCNPJ(cnpjDigits)) {
      setFormError("CNPJ inválido");
      return;
    }

    if (!itemSelected) {
      setFormError("Escolha um ramo de atuação para a unidade");
      return;
    }

    if (itemSelected === "others" && operatingAreasSelected.length === 0) {
      setFormError("Selecione pelo menos um ramo de atuação");
      return;
    }

    try {
      setLoading(true);
      setLoadingCreateBusiness(true);

      const cnpjAlreadyUsed = await checkCNPJExists(cnpjDigits);
      if (cnpjAlreadyUsed) {
        setFormError("CNPJ já registrado");
        return;
      }

      setFormError("");
      const data = {
        name,
        cnpj,
        operatingAreas:
          itemSelected !== "others" ? [parseInt(itemSelected)] : operatingAreasSelected.map((area: any) => area.value),
        objectives: [2, 3, 4],
        ...(groupId && { groupId }),
      };

      const resRegisterBusiness = await registerBusiness({ variables: data, fetchPolicy: "no-cache" });
      const biz = resRegisterBusiness.data?.RegisterBusiness;
      if (biz?.id) {
        const dataCreateBP = {
          businessId: biz.id,
          content: optInMessage(biz.name),
          logo: biz.logo || "034d06394dbb6d9037e65adac1570764.jpg",
          imgCupom: "",
        };

        const dataCreateBGP = {
          businessId: biz.id,
          content: optOutMessage(biz.name),
          logo: biz.logo || "034d06394dbb6d9037e65adac1570764.jpg",
        };

        await createBusinessPage({ variables: dataCreateBP, fetchPolicy: "no-cache" });
        const res = await createBusinessGoodbyePage({ variables: dataCreateBGP, fetchPolicy: "no-cache" });
        await saveDefaultButtons(res.data?.CreateBusinessGoodbyePage.id, biz.id);

        biz.operating = data.operatingAreas;
        biz.objectives = data.objectives;
        setNewBusinessInfo(biz);
        setStep(4);
        if (groupId) openSucessModal();
      }
    } catch (err) {
      console.error(err);
      if (groupId) openFailModal();
    } finally {
      setLoadingCreateBusiness(false);
    }
  };

  const defaultSegments = [
    { value: 26, label: "Alimentação e Bebidas" },
    { value: 72, label: "Farmácias e Drogarias" },
    { value: 120, label: "Lojas de cosméticos" },
    { value: 110, label: "Lojas de roupas, calçados e acessórios" },
    { value: 19, label: "Empórios e Mercados" },
  ];

  return (
    <>
      <RegisterFormModalContainer
        animation={animation}
        stepsQnt={groupId ? 0 : 2}
        currentStep={1}
        emoji={imgOfficeBuilding}
        emojiAlt="Emoji de um prédio comercial"
        title="Adicionar uma unidade"
        subTitle={groupId ? "Vamos criar sua nova empresa na BIUD." : "Vamos criar a sua primeira empresa na BIUD."}
        listName="Ramo de Atuação"
        list={defaultSegments}
        listOtherOption={true}
        operatingAreasSelected={operatingAreasSelected}
        setOperatingAreasSelected={setOperatingAreasSelected}
        itemSelected={itemSelected}
        setItemSelected={setItemSelected}
        primaryBtnTitle={primaryBtnTitle || "Continuar"}
        primaryBtnAction={businessRegister}
        primaryBtnLoading={loadingCreateBusiness}
        primaryBtnProps={{ disabled: loading || !name || !cnpj }}
        secondaryBtnTitle={secondaryBtnTitle || "Fazer isso depois"}
        secondaryBtnAction={() => {
          endOnboarding();
          if (!getCompany()) {
            openChooseBusinessGroupModal();
          }
        }}
      >
        <BiudInput
          labelBefore="Nome da Empresa"
          placeholder="Qual o nome da sua empresa?"
          value={name}
          onChange={(e: any) => {
            setName(e.target.value);
            setFormError("");
          }}
        />
        <BiudMaskInput
          type="cnpj"
          value={cnpj}
          onChange={(e) => {
            setCnpj(e.target.value);
            setFormError("");
          }}
        />
        {formError && (
          <span className="form-error-span">
            {formError} <Attention style={{ fontSize: "16px" }} />{" "}
          </span>
        )}
      </RegisterFormModalContainer>
      <Modal visible={internalModalVisible} content={internalModalContent} size="auto" clickOut={() => ""} />
    </>
  );
}
