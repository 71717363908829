import axios from "axios";
import { getToken } from "@/services/auth";

const biudPlansApi = axios.create({
  baseURL: import.meta.env.VITE_API_PLANS_URL,
});

biudPlansApi.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default biudPlansApi;
