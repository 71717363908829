import React, { Suspense } from "react";
import { getCompany, getGroup, isAuthenticated } from "@services/auth";
import { Redirect, Route, useLocation } from "react-router-dom";
import { isAdminPage, isNetworkPage } from "@services/utils";

interface IProtectedRouteProps {
  component: React.FC<any>;
  isPaymentOk: boolean;
  location: any;
  path: string;
  exact?: boolean;
  isEnabled?: boolean;
  suspenseFallback?: React.ReactNode;
  isCs?: boolean;
}

function ProtectedRoute({
  component: Component,
  isPaymentOk,
  location,
  path,
  isEnabled = true,
  suspenseFallback = null,
  isCs = false,
  ...rest
}: IProtectedRouteProps): JSX.Element {
  const currLocation = useLocation()?.pathname;
  const group = getGroup();
  const isAdmPage = isAdminPage();
  const business = getCompany();
  const isNtPage = isNetworkPage();
  const isWelcomePage = currLocation?.includes("welcome");

  const isPageCompanyFree = () => {
    const pagesFree = ["businessregister", "esg-admin"];

    for (const page of pagesFree) {
      if (currLocation?.includes(page)) {
        return true;
      }
    }

    return false;
  };

  const getNotEnabledPath = (): string => {
    if (isNtPage) {
      return "/internal/network/home";
    }

    if (isAdmPage) {
      return "/internal/admin";
    }

    if (isCs) {
      return "/cs/dashboard";
    }

    return "/internal/home";
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect from="*" to="/login" />;
        }

        if (!isPaymentOk && path === "/internal/billing") {
          return <Component path={path} {...props} />;
        }

        if (!isPaymentOk) {
          return (
            <Redirect
              from={`internal/*`}
              to={{
                pathname: `/internal/billing`,
                state: { from: location },
              }}
            />
          );
        }

        if (!isEnabled) {
          return (
            <Redirect
              from={`internal/*`}
              to={{
                pathname: getNotEnabledPath(),
                state: { from: location },
              }}
            />
          );
        }

        if (!isWelcomePage && !isPageCompanyFree() && (!group || !business)) {
          return (
            <Redirect
              from={`internal/*`}
              to={{
                pathname: "/internal/welcome",
                state: { from: location },
              }}
            />
          );
        }

        if (isWelcomePage && business) {
          return (
            <Redirect
              from={`internal/*`}
              to={{
                pathname: "/internal/home",
                state: { from: location },
              }}
            />
          );
        }

        if (suspenseFallback) {
          return (
            <Suspense fallback={suspenseFallback}>
              <Component path={path} {...props} />
            </Suspense>
          );
        }

        return <Component path={path} {...props} />;
      }}
    />
  );
}

export default ProtectedRoute;
