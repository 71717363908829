import { gql } from "@/graphql/apollo";

export const PLANS_BUSINESS_GROUPS_QUERY = gql`
  query Plans_BusinessGroups($id: Int!) {
    Plans {
      data {
        id
        name
        minMonthlySales
        maxMonthlySales
        leadsDataFranchise
        firstUnitPrice
        additionalUnitPrice
        perLeadAdditionalPrice
      }
    }
    BusinessGroups(id: $id) {
      data {
        plan {
          id
          name
        }
      }
    }
  }
`;

export const BUSINESS_GROUPS_QUERY = gql`
  query businessGroups($id: Int!) {
    businessGroups: BusinessGroups(id: $id) {
      data {
        name
        readonly
        plan {
          id
          name
        }
        businesses {
          id
          name
          cnpj
          activePaymentCards {
            id
            description
            displayNumber
            expiration
            expired
            brand
          }
        }
        activeSubscription {
          id
          status
          createdAt
          finishedAt
          dueDay
          expiresAt
          plan {
            id
            name
          }
          maxMonthlySales
          firstUnitPrice
          additionalUnitPrice
          leadsDataFranchise
          perLeadAdditionalPrice
        }
        invoices {
          id
          status
          reference
          description
          paymentMethod
          value
          createdAt
          dueDate
          items {
            id
            quantity
            description
            unitValue
            amount
            discount
          }
          charges {
            id
            url
            value
            billetBarCode
            card {
              brand
              displayNumber
            }
          }
        }
        activePaymentCards {
          id
          description
          displayNumber
          expiration
          expired
          brand
          groupBusiness {
            id
          }
        }
      }
    }
  }
`;

export const BUSINESS_GROUPS_WHATSAPPCONFIG_QUERY = gql`
  query BusinessGroups_WhatsappConfig($id: Int!) {
    businessGroups: BusinessGroups(id: $id) {
      data {
        id
        name
        hasWhatsappConfig
        whatsappConfig {
          id
          status
        }
        whatsappNumbers {
          displayPhoneNumber
        }
      }
    }
  }
`;

export const BUSINESS_GROUP_GOODBYE_PAGE_QUERY = gql`
  query BusinessGroup_GoodbyePage($id: Int!) {
    BusinessGroups(id: $id) {
      data {
        id
        name
        goodbyePage {
          id
          url
          content
          logo
          qrCode
          reasons {
            id
            label
          }
        }
      }
    }
  }
`;

export const BUSINESS_GROUP_SETTINGS_QUERY = gql`
  query BusinessGroups_Config($id: Int!) {
    Plans {
      data {
        id
        name
      }
    }
    BusinessGroups(id: $id) {
      data {
        activeSubscription {
          plan {
            id
            name
          }
        }
      }
    }
  }
`;

export const BUSINESS_GROUPS_SELECT_QUERY = gql`
  query businessGroups($id: Int) {
    businessGroups: BusinessGroups(id: $id, businessActive: true) {
      data {
        id
        name
      }
    }
  }
`;

export const BUSINESS_GROUPS_ENRICHMENT_QUERY = gql`
  query businessGroupsEnrichment($id: Int, $page: Int, $maxPerPage: Int, $sortBy: String, $orderBy: OrderBy) {
    businessGroups: BusinessGroups(id: $id, page: $page, maxPerPage: $maxPerPage, sortBy: $sortBy, orderBy: $orderBy) {
      data {
        id
        name
        enrichmentAcquisitionLimit
        isEnrichmentAcquisitionEnabled
      }
      pagination {
        currentPage
        nextPage
        maxPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const BUSINESS_GROUPS_PLANS_TABLE = gql`
  query businessGroupsEnrichment($id: Int, $page: Int, $maxPerPage: Int, $sortBy: String, $orderBy: OrderBy) {
    businessGroups: BusinessGroups(id: $id, page: $page, maxPerPage: $maxPerPage, sortBy: $sortBy, orderBy: $orderBy) {
      data {
        id
        name
        createdAt
      }
      pagination {
        currentPage
        nextPage
        maxPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const BUSINESS_GROUPS_TRIAL_QUERY = gql`
  query businessGroupsTrial($id: Int!) {
    BusinessGroups(id: $id) {
      data {
        name
        createdAt
        trialDays
        remainingTrialDays
        businessAmount
      }
    }
  }
`;

export const GROUP_EXTERNAL_ID = gql`
  query GroupExternalId {
    BusinessGroups(businessDataSharingEnabled: true, businessActive: true) {
      data {
        name
        externalId
      }
    }
  }
`;
