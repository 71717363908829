import React, { useEffect, useState } from "react";
import "../styles.css";
import Modal from "../../Modal";
import Tooltip from "rc-tooltip";
import { Link } from "react-router-dom";
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import Version from "../../Version/Version";
import MoreInfoImg from "@imgs/more-info.svg";
import { imgLoading, iconArrowSelect } from "@imgs";
import { INavMenuBusinessGroup } from "@components/Menu/types";
import Store from "@newcomponentes/CustomIcons/Generics/Store";
import Exchange from "@newcomponentes/CustomIcons/Generics/Exchange";
import MemberKit from "@newcomponentes/CustomIcons/Generics/MemberKit";
import BiudLogoFull from "@newcomponentes/CustomIcons/Generics/BiudLogoFull";
import BuildingSmall from "@newcomponentes/CustomIcons/Generics/BuildingSmall";
import navMenuOptions, { IMenuOption } from "@components/Menu/NavMenu/options";
import ArrowUpRightSmall from "@newcomponentes/CustomIcons/Arrows/ArrowUpRightSmall";
import { ISelectedBusiness } from "@pages/CustomersB2B/modals/CustomerDetails/types";
import { getCompany, getGroup, getMemberKit, getMenuPreference, setMemberKit, setMenuPreference } from "@services/auth";
import { getAuthenticatedUrl } from "@services/apis/memberkit/requests";

interface INavMenuProps {
  business: ISelectedBusiness;
  openChooseBusinessModal: () => void;
  selectedLink: string;
  isModalVisible: boolean;
  modalContent: JSX.Element;
  modalSize: string;
  businessGroup: INavMenuBusinessGroup;
  openChooseBusinessGroupModal: () => void;
  optionsEnabled: string[];
}

export default function NavMenu({
  business,
  openChooseBusinessModal,
  selectedLink,
  isModalVisible,
  modalContent,
  modalSize,
  businessGroup,
  openChooseBusinessGroupModal,
  optionsEnabled,
}: INavMenuProps) {
  const group = getGroup();
  const isBusinessSelected = !!getCompany();
  const companyStoreData = useSelector((store: any) => store.company);
  const userSubscriptionInfo = useSelector((store: any) => store.user.subscription);

  const url = window.location.href;
  const bizGrMenu = url.includes("network");
  const enterprise = userSubscriptionInfo?.isGroupViewEnabled || false;

  const [openedMenu, setOpenedMenu] = useState(getMenuPreference() || "");

  const [memberKitUrl, setMemberKitUrl] = useState(getMemberKit() || "");
  const [loading, setLoading] = useState(!memberKitUrl);

  async function getMemberKitUrl() {
    try {
      if (memberKitUrl) return;

      setLoading(true);
      const memberUrl = await getAuthenticatedUrl();
      setMemberKit(memberUrl);
      setMemberKitUrl(memberUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function shouldShowOption(op: IMenuOption) {
    return op.isEnabled && (optionsEnabled.includes(op.optionName) || optionsEnabled.includes("full"));
  }

  function toggleMenu(optionName: string) {
    const newValue = openedMenu === optionName ? "" : optionName;
    setOpenedMenu(newValue);
    setMenuPreference(newValue);
  }

  function renderToolTip(tooltip?: string) {
    if (!tooltip) {
      return <></>;
    }

    return (
      <Tooltip placement={"right"} overlayStyle={{ maxWidth: "245px" }} overlay={tooltip}>
        <img id={`calibration-tooltip-img`} src={MoreInfoImg} alt="More Info" />
      </Tooltip>
    );
  }

  useEffect(() => {
    getMemberKitUrl();
  }, []);

  if (!group) return <></>;

  return (
    <div className={`menu ${enterprise && bizGrMenu && "menu-enterprise"}`}>
      {enterprise && bizGrMenu ? (
        <div className="menu-business-group">
          <div title={businessGroup?.name} className="business-group-menu-header">
            <span>{businessGroup?.name ?? <img src={imgLoading} alt="loading..." width="25px" />}</span>
            <div className="btns-div">
              <button
                id="btn-navmenu-openchoosenetworkmodal"
                className="change-btn"
                title="Trocar de rede"
                onClick={openChooseBusinessGroupModal}
              >
                <Exchange viewBox="-2 -3 20 20" style={{ color: enterprise ? "white" : "black" }} />
              </button>
            </div>
          </div>
          {navMenuOptions.network.map((op) => {
            if (!shouldShowOption(op)) return null;
            const isOpen = openedMenu === op.optionName;
            if (op.url && op.routeName) {
              return (
                <Link key={`network-menu-${op.routeName}`} id={op.id} to={op.url} title={op.title}>
                  <div
                    onClick={() => toggleMenu(op.optionName)}
                    className={`item item-centralizado menu-name ${
                      selectedLink.includes(op.routeName) ? "selected" : ""
                    } ${enterprise ? "item-enterprise" : ""}`}
                  >
                    {op.icon}
                    {op.name}
                    {renderToolTip(op?.tooltip)}
                  </div>
                </Link>
              );
            }
            return (
              <div key={`network-menu-${op.id}`}>
                <div
                  className={`item item-centralizado menu-name ${enterprise ? "item-enterprise" : ""}`}
                  onClick={() => toggleMenu(op.optionName)}
                  title={`Expandir ou recolher módulo de ${op.name}`}
                >
                  {op.name}
                  <img
                    alt="seta indicando menu aberto ou fechado"
                    src={iconArrowSelect}
                    className={`multiple-select-arrow-animated ${isOpen ? "expand" : "collapse"} menu-arrow`}
                  />
                </div>
                <div className="sub-items">
                  <Collapse in={isOpen}>
                    {op.options?.map(
                      (op: IMenuOption) =>
                        op.isEnabled &&
                        op.url &&
                        op.routeName && (
                          <Link key={`network-menu-${op.routeName}`} id={op.id} to={op.url} title={op.title}>
                            <div
                              className={`item item-centralizado ${
                                selectedLink.includes(op.routeName) ? "selected" : ""
                              } ${enterprise ? "item-enterprise" : ""}`}
                            >
                              {op.icon}
                              {op.name}
                            </div>
                          </Link>
                        )
                    )}
                  </Collapse>
                </div>
              </div>
            );
          })}
          <hr />
          <div className="redirect-buttons-container">
            <a href="/internal/home" target="_blank" title="Abrir visualização de unidade" className="see-units-link">
              <div className={`item ${enterprise ? "item-enterprise" : ""}`}>
                <Store style={{ fontSize: "14px" }} />
                Unidades
                <ArrowUpRightSmall style={{ width: 16, height: 16, marginBottom: -2 }} viewBox="0 0 16 16" />
              </div>
            </a>
            {!loading && !!memberKitUrl && (
              <a
                href={memberKitUrl}
                target="_blank"
                title="Abrir página do MemberKit"
                className="see-units-link"
                rel="noreferrer"
              >
                <div className={`item ${enterprise ? "item-enterprise" : ""}`} style={{ margin: 0 }}>
                  <MemberKit style={{ fontSize: "16px" }} />
                  Academy
                  <ArrowUpRightSmall style={{ width: 16, height: 16, marginBottom: -2 }} viewBox="0 0 16 16" />
                </div>
              </a>
            )}
          </div>
        </div>
      ) : (
        isBusinessSelected && (
          <div className="menu-business-group">
            <div className="business-menu-header">
              {!business?.name ? (
                <div className="flex flex-row-center" style={{ width: "100%", paddingLeft: "24px" }}>
                  <img src={imgLoading} alt="loading..." width="25px" />
                </div>
              ) : (
                <Tooltip
                  key={crypto.randomUUID()}
                  placement="bottom"
                  // @ts-ignore
                  onClick={() => copyToClipboard(hasMail)}
                  overlay={<span className="business-name-tooltip">{business?.name}</span>}
                >
                  <div
                    className={`${
                      enterprise
                        ? `business-menu-header-enterprise ${business?.name?.length > 15 ? "marquee" : ""}`
                        : `${business?.name?.length > 15 ? "marquee" : ""}`
                    }`}
                  >
                    <span>{business?.name}</span>
                  </div>
                </Tooltip>
              )}
              <div className="btns-div">
                <button
                  id="btn-navmenu-openchoosebizmodal"
                  className="change-btn"
                  onClick={
                    companyStoreData.isChooseGroupBusinessRequired
                      ? openChooseBusinessGroupModal
                      : openChooseBusinessModal
                  }
                  title="Trocar de business"
                >
                  <Exchange viewBox="-2 -3 20 20" />
                </button>
              </div>
            </div>
            {navMenuOptions.unit.map((op: IMenuOption) => {
              if (!shouldShowOption(op)) return null;
              const isOpen = openedMenu === op.optionName;
              if (op.url && op.routeName) {
                return (
                  <Link key={`network-menu-${op.routeName}`} id={op.id} to={op.url} title={op.title}>
                    <div
                      onClick={() => toggleMenu(op.optionName)}
                      className={`item item-centralizado menu-name ${
                        selectedLink.includes(op.routeName) ? "selected" : ""
                      } ${op?.isAdminOnly ? "menu-admin-only" : ""}`}
                    >
                      {op.icon}
                      {op.name}
                      {renderToolTip(op?.tooltip)}
                    </div>
                  </Link>
                );
              }
              return (
                <div key={`network-menu-${op.id}`}>
                  <div
                    className="item item-centralizado menu-name"
                    onClick={() => toggleMenu(op.optionName)}
                    title={`Expandir ou recolher módulo de ${op.name}`}
                  >
                    {op.name}
                    <img
                      alt="seta indicando menu aberto ou fechado"
                      src={iconArrowSelect}
                      className={`multiple-select-arrow-animated ${isOpen ? "expand" : "collapse"} menu-arrow`}
                    />
                  </div>
                  <div className="sub-items">
                    <Collapse in={isOpen}>
                      {op.options?.map(
                        (op: IMenuOption) =>
                          op.url &&
                          op.routeName && (
                            <Link key={`network-menu-${op.routeName}`} id={op.id} to={op.url} title={op.title}>
                              <div
                                className={`item item-centralizado ${
                                  selectedLink.includes(op.routeName) ? "selected" : ""
                                }`}
                              >
                                {op.icon}
                                {op.name}
                              </div>
                            </Link>
                          )
                      )}
                    </Collapse>
                  </div>
                </div>
              );
            })}
            <hr style={{ marginTop: "16px", borderTop: "1px solid #e3e3e3" }} />
            <div className="redirect-buttons-container">
              {enterprise && (
                <>
                  <div className="see-network-container">
                    <a
                      href="/internal/network/home"
                      target="_blank"
                      title="Abrir visualização de rede"
                      className="see-network-link"
                    >
                      <div className={`item ${enterprise && bizGrMenu ? "item-enterprise" : ""}`}>
                        <BuildingSmall style={{ fontSize: "14px" }} />
                        Rede
                        <ArrowUpRightSmall style={{ width: 16, height: 16, marginBottom: -2 }} viewBox="0 0 16 16" />
                      </div>
                    </a>
                    <button
                      id="btn-navmenu-openchoosenetworkmodal"
                      className="change-btn"
                      title="Trocar de rede"
                      onClick={openChooseBusinessGroupModal}
                    >
                      <Exchange viewBox="-2 -3 20 20" style={{ color: "#666" }} />
                    </button>
                  </div>
                </>
              )}
              {!loading && !!memberKitUrl && (
                <div className="see-network-container">
                  <a
                    href={memberKitUrl}
                    target="_blank"
                    title="Abrir página do MemberKit"
                    className="see-network-link"
                    rel="noreferrer"
                  >
                    <div className={`item ${enterprise && bizGrMenu ? "item-enterprise" : ""}`} style={{ margin: 0 }}>
                      <MemberKit style={{ fontSize: "16px" }} />
                      Academy
                      <ArrowUpRightSmall style={{ width: 16, height: 16, marginBottom: -2 }} viewBox="0 0 16 16" />
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        )
      )}

      <div className="version-container">
        <BiudLogoFull viewBox="0 0 97 32" style={{ color: "#D81656", width: "97px", height: "32px" }} />
        <Version />
      </div>

      <Modal visible={isModalVisible} content={modalContent} size={modalSize} />
    </div>
  );
}
