import React, { useMemo, useState } from "react";
import "./styles.css";
import NavMenu from "./NavMenu";
import { useEffect } from "react";
import { getBusinessUsers } from "@query/business";
import navMenuOptions from "../../data/navMenuOptions";
import { useDispatch, useSelector } from "react-redux";
import { getGroupReadOnly } from "@query/businessGroup";
import { setCurrentUser } from "@store/CurrentUser/CurrentUserAction";
import ChooseBusinessModalContent from "../ChooseBusinessModalContent";
import ChooseBusinessGroupModalContent from "../ChooseBusinessGroupModalContent";
import {
  addUserType,
  existsUserType,
  getCompany,
  getCurrentUserEmail,
  getGroup,
  getIsUserEsgAdminNacional,
  getIsUserEsgAdminRegional,
  isAdmin,
  isSeller,
  logout,
  setGroup,
} from "@services/auth";
import { INavMenuBusinessGroup } from "@components/Menu/types";
import { ISelectedBusiness } from "@pages/CustomersB2B/modals/CustomerDetails/types";
import EsgAdminMenu from "@components/Menu/EsgAdminMenu";

export default function Menu() {
  const userType = useSelector((store: any) => store.user.userType);
  const dispatch = useDispatch();
  const selectedLink = location.pathname;
  const { FULL, CUSTOMERS } = navMenuOptions;
  const group = getGroup();
  const isEsgAdmin = useMemo(() => getIsUserEsgAdminNacional() || getIsUserEsgAdminRegional(), []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [modalSize, setModalSize] = useState("");

  const [businessGroup, setBusinessGroup] = useState({} as INavMenuBusinessGroup);
  const [business, setBusiness] = useState({} as ISelectedBusiness);

  if (business.id && !isAdmin()) {
    if (!existsUserType()) {
      if (!group?.readonly) logout();
    }
  }

  const getBusinessUsersList = async () => {
    if (isAdmin() && userType === "") {
      dispatch(setCurrentUser("ADMIN"));
    } else {
      if (business.id && userType === "") {
        try {
          const userEmail = getCurrentUserEmail();

          const { data } = await getBusinessUsers(business.id);
          const { readonly } = await getGroupReadOnly();
          setGroup({ ...group, readonly: readonly || false });
          if (!readonly) {
            const usersList = data.Business[0].users;
            const currentUser = usersList.find(({ user }: any) => user.email === userEmail);
            dispatch(setCurrentUser(currentUser.type));
            addUserType(currentUser.type);
          } else {
            dispatch(setCurrentUser("OWNER"));
            addUserType("OWNER");
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  useEffect(() => {
    getBusinessUsersList();
  }, [business, userType]);

  useEffect(() => {
    const group = getGroup();
    if (!group) {
      if (!selectedLink.includes("dashboard") && !isEsgAdmin) {
        openChooseBusinessGroupModal();
      }
      return;
    }
    setBusinessGroup(group);

    const business = getCompany();
    if (!business) {
      if (!selectedLink.includes("dashboard") && !isEsgAdmin) {
        openChooseBusinessModal();
      }
      return;
    }
    setBusiness(business);
  }, []);

  const closeModal = () => {
    setIsModalVisible(false);
    setModalContent(<></>);
  };

  const openChooseBusinessModal = () => {
    setModalContent(
      <ChooseBusinessModalContent
        onClose={closeModal}
        openChooseBusinessGroupModal={openChooseBusinessGroupModal}
        backBtnCallback={openChooseBusinessGroupModal}
        setModalContent={setModalContent}
        setModalVisible={setIsModalVisible}
        setModalSize={setModalSize}
      />
    );

    setIsModalVisible(true);
    setModalSize("x-small");
  };

  function openChooseBusinessGroupModal() {
    setModalContent(<ChooseBusinessGroupModalContent onClose={closeModal} callBack={openChooseBusinessModal} />);

    setIsModalVisible(true);
    setModalSize("x-small");
  }

  if (isEsgAdmin) {
    return <EsgAdminMenu />;
  }

  return (
    <NavMenu
      business={business}
      openChooseBusinessModal={openChooseBusinessModal}
      selectedLink={selectedLink}
      isModalVisible={isModalVisible}
      modalContent={modalContent}
      modalSize={modalSize}
      businessGroup={businessGroup}
      openChooseBusinessGroupModal={openChooseBusinessGroupModal}
      optionsEnabled={isSeller() ? [CUSTOMERS] : !existsUserType() && !isAdmin() ? [] : [FULL]}
    />
  );
}
