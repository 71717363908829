import { ICreatePlanResponse } from "@services/apis/biudPlans/types";

export const convertPlanCentsValueToReais = (plans: ICreatePlanResponse[]): ICreatePlanResponse[] => {
  return (
    plans?.map((p: ICreatePlanResponse) => {
      return {
        ...p,
        base_value: `${+p.base_value / 100}`,
        additional_enterprise_value: `${+p.additional_enterprise_value / 100}`,
      };
    }) || []
  );
};
