import React, { ButtonHTMLAttributes, CSSProperties, ReactNode } from "react";
import "./styles.scss";

export const biudButtonTypes = [
  "primary",
  "secondary",
  "tertiary",
  "quaternary",
  "quinary",
  "senary",
  "success",
  "biud",
  "white",
  "disabled",
  "primary-outlined",
  "secondary-outlined",
  "tertiary-outlined",
  "quaternary-outlined",
  "quinary-outlined",
  "success-outlined",
  "alert-outlined",
  "cookie-outlined",
  "text-primary",
  "text-secondary",
  "text-tertiary",
  "text-quaternary",
  "disabled-outlined",
] as const;

export type TBiudButtonType = (typeof biudButtonTypes)[number];

export interface IBiudButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  buttonType: TBiudButtonType;
  size?: "sm" | "md" | "lg";
  bold?: boolean;
  isCollapse?: boolean;
  style?: CSSProperties;
}

function BiudButton({
  id = "btn-biud-button",
  className = "",
  onClick = () => "",
  children,
  buttonType = "primary",
  size = "sm",
  bold = false,
  isCollapse = false,
  style = {},
  ...rest
}: IBiudButtonProps) {
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      style={style}
      {...rest}
      className={`btn-${buttonType} btn-size-${size} ${!bold ? "btn-bold-false" : ""} ${
        isCollapse ? "collapse-btn" : ""
      } ${className}`}
    >
      {children}
    </button>
  );
}

export default BiudButton;
