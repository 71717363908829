import { useState } from "react";
import { getGroup } from "@services/auth";
import { useDispatch, useSelector } from "react-redux";
import CompanyAction from "@store/Company/CompanyAction";
import { getNetworkPaymentData } from "@query/businessGroup";
import { checkPayment } from "@store/CurrentUser/CurrentUserAction";

export interface IAccessData {
  allowAccess: boolean;
  data: any;
  checkAgainAt: Date;
  isGroupViewEnabled: boolean;
}

function useCheckAccess() {
  const [accessData, setAccessData] = useState<IAccessData>({} as IAccessData);
  const [loadingCheckAccess, setLoadingCheckAccess] = useState(true);

  const dispatch = useDispatch();
  const checkPaymentInfo = useSelector((store: any) => store.user.subscription);

  const checkAccess = async (realTimeData: any = null, forceRefresh = false) => {
    const group = getGroup();
    if (!group) return;

    if (new Date(checkPaymentInfo.checkAgainAt) > new Date() && !forceRefresh) return;

    dispatch(CompanyAction.setLoading(true));
    setLoadingCheckAccess(true);

    try {
      const res = await getNetworkPaymentData();

      if (!res) return console.error("Erro na getNetworkPaymentData");

      const businessAmount = res?.businessAmount || 0;
      const payload = {
        allowAccess: !!res?.remainingTrialDays || !!realTimeData?.active,
        data: res || {},
        checkAgainAt: new Date(Date.now() + 3600 * 1000 * 24),
        isGroupViewEnabled: businessAmount > 1,
      };

      setAccessData(payload);
      dispatch(checkPayment(payload));
    } catch (err) {
      console.error((err as Error).message);
    } finally {
      dispatch(CompanyAction.setLoading(false));
      setLoadingCheckAccess(true);
    }
  };

  return { accessData, loadingCheckAccess, checkAccess };
}

export default useCheckAccess;
