import { gql } from "@/graphql/apollo";

export const BUSINESS_LAT_LONG_QUERY = gql`
  query Business_LatLong($businessId: Int) {
    business: Business(id: $businessId) {
      data {
        lat
        long
      }
    }
  }
`;

export const BUSINESS_WHATSAPPCONFIG_QUERY = gql`
  query Business_WhatsApp_Config(
    $id: Int
    $owner: Int
    $groupId: Int
    $name: String
    $dataSharingEnabled: Boolean
    $active: Boolean
    $page: Int
    $maxPerPage: Int
    $sortBy: String
    $orderBy: OrderBy
  ) {
    Business(
      id: $id
      owner: $owner
      groupId: $groupId
      name: $name
      dataSharingEnabled: $dataSharingEnabled
      active: $active
      page: $page
      maxPerPage: $maxPerPage
      sortBy: $sortBy
      orderBy: $orderBy
    ) {
      data {
        id
        name
        hasWhatsappConfig
        whatsappConfig {
          id
          status
        }
        whatsappNumbers {
          displayPhoneNumber
        }
      }
      pagination {
        currentPage
        nextPage
        maxPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const HASCERTIFIED_AND_PRODUCTNOTREVIEWED_QUERY = gql`
  query Business_HasCertifiedAndProductNotReviewed($id: Int) {
    Business(id: $id) {
      data {
        certificates {
          id
          processedAt
          status
        }
        hasCertificate
      }
    }
    Products(businessId: $id, reviewed: "false", page: 1, maxPerPage: 5) {
      data {
        id
        description
      }
    }
  }
`;

export const SCHEDULE_BUSINESS_CERTIFICATE_PROCESS_QUERY = gql`
  query ScheduleBusinessCertificateProcess($businessId: Int) {
    ScheduleBusinessCertificateProcess(business: $businessId) {
      startedAt
      endedAt
    }
  }
`;

export const BUSINESS_FROM_GROUP = gql`
  query Business_fromGroup($groupId: Int) {
    Business(groupId: $groupId) {
      data {
        id
      }
    }
  }
`;

export const BUSINESS_NO_PRODUCTS_QUERY = gql`
  query BusinessNoProducts($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
    Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
      data {
        id
        name
        cnpj
        facebook
        instagram
        siteUrl
        createdAt
        city {
          name
          ibge
        }
        owner {
          name
        }
        objectives {
          description
          id
        }
        operatingAreas {
          name
          id
        }
        biudConnect
        isDataSharingEnabled
        logo
        RFV
        facebookPage {
          isUserTokenExpired
          isPageTokenExpired
          lastDataUpdate
          facebookPageId
          pageToken
          businessManagerId
          adAccountId
          businessManagers {
            id
            name
          }
        }
        currentAddress {
          postalCode
          street
          district
          complement
          number
          city {
            ibge
          }
        }
        businessFiscalCodes {
          id
          description
          initial
          final
        }
        lat
        long
      }
    }
  }
`;

export const BUSINESS_RFV_QUERY = gql`
  query BusinessRFV($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
    Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
      data {
        RFV
      }
    }
  }
`;

export const BUSINESS_OPT_IN_PAGE_QUERY = gql`
  query BusinessOptInPage($id: Int) {
    Business(id: $id) {
      data {
        name
        page {
          id
          url
          content
          logo
          qrCode
          imgCupom
          actions {
            id
            url
            label
          }
        }
        group {
          id
          name
        }
      }
    }
  }
`;

export const BUSINESS_OPT_OUT_PAGE_QUERY = gql`
  query BusinessOptOutPage($id: Int) {
    Business(id: $id) {
      data {
        name
        goodbyePage {
          id
          url
          content
          logo
          qrCode
          reasons {
            id
            label
          }
        }
      }
    }
  }
`;

export const BUSINESS_NO_PRODUCTS_OPTIN_OPTOUT_QUERY = gql`
  query BusinessOptinOptout($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
    Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
      data {
        id
        name
        cnpj
        facebook
        instagram
        siteUrl
        createdAt
        city {
          name
          ibge
        }
        owner {
          name
        }
        objectives {
          description
          id
        }
        operatingAreas {
          name
          id
        }
        biudConnect
        isDataSharingEnabled
        logo
        RFV
        facebookPage {
          isUserTokenExpired
          isPageTokenExpired
          lastDataUpdate
          facebookPageId
          pageToken
          businessManagerId
          adAccountId
          businessManagers {
            id
            name
          }
        }
        currentAddress {
          postalCode
          street
          district
          complement
          number
          city {
            ibge
          }
        }
        businessFiscalCodes {
          id
          description
          initial
          final
        }
        goodbyePage {
          id
          url
          content
          logo
          qrCode
          reasons {
            id
            label
          }
        }
        page {
          id
          url
          content
          logo
          qrCode
          imgCupom
          actions {
            id
            url
            label
          }
        }
        group {
          id
          name
        }
        users {
          type
          user {
            id
            name
            email
            phone
            cpf
            enabled
          }
        }
        configs {
          noiseControlDays
        }
        lat
        long
      }
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    CurrentUser {
      id
      name
      email
      phone
    }
  }
`;

export const BUSINESS_LOGS_QUERY = gql`
  query BusinessLogs($id: Int) {
    Business(id: $id) {
      data {
        logs {
          message
          user {
            id
            name
            email
          }
          createdAt
          metadata {
            key
            value
          }
        }
      }
    }
  }
`;

export const BUSINESS_USERS_QUERY = gql`
  query Business_businessUsers($id: Int) {
    Business(id: $id) {
      data {
        users {
          type
          user {
            id
            name
            email
            phone
            cpf
            enabled
          }
        }
      }
    }
  }
`;

export const BIUD_CONNECT_INSTALLED_QUERY = gql`
  query Business($id: Int) {
    Business(id: $id) {
      data {
        biudConnect
      }
    }
  }
`;

export const BUSINESS_FACEBOOK_INFO_QUERY = gql`
  query BusinessFacebookInfo($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
    Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
      data {
        facebookPage {
          isUserTokenExpired
          isPageTokenExpired
          isFacebookLimitReached
          lastDataUpdate
          facebookPageId
          pageToken
          businessManagerId
          adAccountId
          businessManagers {
            id
            name
          }
        }
      }
    }
  }
`;

export const BUSINESS_CERTIFICATES_QUERY = gql`
  query Business($id: Int) {
    Business(id: $id) {
      data {
        certificates {
          id
          processedAt
          status
        }
        hasCertificate
      }
    }
  }
`;

export const BUSINESS_CNPJ_QUERY = gql`
  query BusinessCnpj($cnpj: String!) {
    Business(cnpj: $cnpj) {
      data {
        id
      }
    }
  }
`;

export const BUSINESS_LEADS_QUERY = gql`
  query BusinessLeads($id: Int) {
    Business(id: $id) {
      data {
        enrichmentRemainingCredits
        enrichmentConsumedCredits
      }
    }
  }
`;

export const BUSINESS_EXTERNAL_ID_BY_NAME = gql`
  query BusinessExternalIdByName($businessName: String) {
    Business(name: $businessName) {
      data {
        name
        externalId
        group {
          name
        }
      }
    }
  }
`;

export const BUSINESS_HAVE_PURCHASE_QUERY = gql`
  query BusinessHavePurchases($businessId: Int) {
    Business(id: $businessId) {
      data {
        name
        havePurchases
      }
    }
  }
`;
