import { client, gql } from "../config";
import { getGroup } from "@services/auth";
import { FetchPolicy } from "apollo-boost";
import { BUSINESS_GROUPS_TRIAL_QUERY } from "@query/gql/businessGroup";

export async function getNetworkPaymentData(fetchPolicy: FetchPolicy = "no-cache") {
  const group = getGroup();
  if (!group) return null;
  const res = await client.query({
    query: BUSINESS_GROUPS_TRIAL_QUERY,
    variables: { id: group.id },
    fetchPolicy,
  });

  return res.data.BusinessGroups?.data?.[0] || [];
}

export async function getGroupReadOnly() {
  const group = getGroup();
  if (!group) return null;
  const getBusinessGroupQuery = gql`
    query businessGroups($id: Int!) {
      businessGroups: BusinessGroups(id: $id) {
        data {
          name
          readonly
        }
      }
    }
  `;
  const groups = await client.query({
    query: getBusinessGroupQuery,
    variables: { id: group.id },
    fetchPolicy: "no-cache",
  });

  return groups.data.businessGroups.data[0];
}

export async function getPlans() {
  const query = gql`
    query plans {
      plans: Plans {
        data {
          id
          name
          minMonthlySales
          maxMonthlySales
          leadsDataFranchise
          firstUnitPrice
          additionalUnitPrice
          perLeadAdditionalPrice
        }
      }
    }
  `;
  const res = await client.query({
    query,
  });
  const plans = res.data.plans.data;
  return { plans };
}
