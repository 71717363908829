import { gql } from "@/graphql/apollo";

export const REGISTER_BUSINESS_CERTIFICATE_MUTATION = gql`
  mutation RegisterBusinessCertificate(
    $businessId: Int!
    $pinCode: String!
    $certificateBuffer: String!
    $expiresAt: Date
  ) {
    RegisterBusinessCertificate(
      businessId: $businessId
      pinCode: $pinCode
      certificateBuffer: $certificateBuffer
      expiresAt: $expiresAt
    )
  }
`;

export const REMOVE_BUSINESS_CERTIFICATE_MUTATION = gql`
  mutation RemoveBusinessCertificate($businessId: Int!, $certificateId: Int!) {
    RemoveBusinessCertificate(businessId: $businessId, certificateId: $certificateId)
  }
`;

export const ADD_SCHEDULE_BUSINESS_CERTIFICATE_PROCESS_MUTATION = gql`
  mutation AddScheduleBusinessCertificateProcess($businessId: Int!, $startedAt: Date!, $endedAt: Date!) {
    AddScheduleBusinessCertificateProcess(businessId: $businessId, startedAt: $startedAt, endedAt: $endedAt)
  }
`;

export const UPDATE_BUSINESS_MUTATION = gql`
  mutation UpdateBusiness(
    $businessId: Int!
    $name: String!
    $cnpj: String!
    $facebook: String
    $instagram: String
    $siteUrl: String
    $objectives: [Int]
    $operatingAreas: [Int]
    $logo: String
    $cep: String
    $street: String
    $number: String
    $complement: String
    $district: String
    $cityId: Int
    $lat: String
    $long: String
  ) {
    UpdateBusiness(
      businessId: $businessId
      name: $name
      cnpj: $cnpj
      facebook: $facebook
      instagram: $instagram
      siteUrl: $siteUrl
      objectives: $objectives
      operatingAreas: $operatingAreas
      logo: $logo
      cep: $cep
      street: $street
      number: $number
      complement: $complement
      district: $district
      cityId: $cityId
      lat: $lat
      long: $long
    )
  }
`;

export const UPDATE_BUSINESS_RFV_MUTATION = gql`
  mutation UpdateBusinessRFV($businessId: Int!, $rfv: String!) {
    UpdateBusinessRFV(businessId: $businessId, rfv: $rfv)
  }
`;

export const ADD_BUSINESS_GOODBYE_REASON_MUTATION = gql`
  mutation AddBusinessGoodbyeReason($goodbyePageId: Int!, $label: String!) {
    AddBusinessGoodbyeReason(goodbyePageId: $goodbyePageId, label: $label)
  }
`;

export const UPDATE_BUSINESS_GOODBYE_PAGE_MUTATION = gql`
  mutation UpdateBusinessGoodbyePage($goodbyePageId: Int!, $content: String!, $logo: String) {
    UpdateBusinessGoodbyePage(goodbyePageId: $goodbyePageId, content: $content, logo: $logo)
  }
`;

export const REMOVE_BUSINESS_GOODBYE_REASON_MUTATION = gql`
  mutation RemoveBusinessGoodbyeReason($reasonId: Int!, $goodbyePageId: Int!) {
    RemoveBusinessGoodbyeReason(reasonId: $reasonId, goodbyePageId: $goodbyePageId)
  }
`;

export const CREATE_BUSINESS_GOODBYE_PAGE_MUTATION = gql`
  mutation CreateBusinessGoodbyePage($businessId: Int, $groupId: Int, $content: String!, $logo: String) {
    CreateBusinessGoodbyePage(businessId: $businessId, groupId: $groupId, content: $content, logo: $logo) {
      id
    }
  }
`;

export const ADD_BUSINESS_PAGE_ACTION_MUTATION = gql`
  mutation AddBusinessPageAction($businessId: Int!, $pageId: Int!, $url: String!, $label: String!) {
    AddBusinessPageAction(businessId: $businessId, pageId: $pageId, url: $url, label: $label)
  }
`;

export const UPDATE_BUSINESS_PAGE_MUTATION = gql`
  mutation UpdateBusinessPage($pageId: Int!, $content: String!, $logo: String, $imgCupom: String) {
    UpdateBusinessPage(pageId: $pageId, content: $content, logo: $logo, imgCupom: $imgCupom)
  }
`;

export const REMOVE_BUSINESS_PAGE_ACTION_MUTATION = gql`
  mutation RemoveBusinessPageAction($actionId: Int!, $pageId: Int!) {
    RemoveBusinessPageAction(actionId: $actionId, pageId: $pageId)
  }
`;

export const CREATE_BUSINES_PAGE_MUTATION = gql`
  mutation CreateBusinessPage($businessId: Int!, $content: String!, $logo: String, $imgCupom: String) {
    CreateBusinessPage(businessId: $businessId, content: $content, logo: $logo, imgCupom: $imgCupom) {
      id
    }
  }
`;

export const REGISTER_BUSINESS_MUTATION = gql`
  mutation RegisterBusiness(
    $name: String!
    $cnpj: String
    $facebook: String
    $instagram: String
    $siteUrl: String
    $ibge: Int
    $objectives: [Int]!
    $operatingAreas: [Int]!
    $logo: String
    $groupId: Int
    $lat: String
    $long: String
  ) {
    RegisterBusiness(
      name: $name
      cnpj: $cnpj
      facebook: $facebook
      instagram: $instagram
      siteUrl: $siteUrl
      ibge: $ibge
      objectives: $objectives
      operatingAreas: $operatingAreas
      logo: $logo
      groupId: $groupId
      lat: $lat
      long: $long
    ) {
      id
      name
      cnpj
      facebook
      instagram
      siteUrl
      createdAt
      city {
        name
      }
      owner {
        name
      }
      objectives {
        description
      }
      operatingAreas {
        name
      }
      products {
        description
      }
    }
  }
`;

export const CALCULATE_BUSINESS_RFV_MUTATION = gql`
  mutation CalculateBusinessRFV($businessId: Int!) {
    CalculateBusinessRFV(businessId: $businessId)
  }
`;
