import React, { memo, useEffect } from "react";
import "./styles.scss";
import { CheckCircle } from "@newcomponentes/CustomIcons";

interface IPasswordValidatorStepsProps {
  password: string;
  setIsPasswordValid: (value: boolean) => void;
  passwordMinLength?: number;
  validatePasswordLength?: boolean;
  validateUpperCase?: boolean;
  validateLowerCase?: boolean;
  validateHasNumber?: boolean;
  passwordConfirmation?: string;
}

function PasswordValidatorSteps({
  password = "",
  setIsPasswordValid,
  passwordMinLength = 8,
  validatePasswordLength = true,
  validateUpperCase = true,
  validateLowerCase = true,
  validateHasNumber = true,
  passwordConfirmation,
}: IPasswordValidatorStepsProps) {
  const validators = [
    { title: "Mínimo de 8 caracteres", status: password.length >= passwordMinLength, enabled: validatePasswordLength },
    { title: "Um número", status: password.match(".*[0-9].*"), enabled: validateHasNumber },
    { title: "Uma letra maiúscula", status: password.match(".*[A-Z].*"), enabled: validateUpperCase },
    { title: "Uma letra minúscula", status: password.match(".*[a-z].*"), enabled: validateLowerCase },
    {
      title: "Senhas correspondentes",
      status: password === passwordConfirmation && passwordConfirmation !== "",
      enabled: passwordConfirmation === "" || !!passwordConfirmation,
    },
  ];

  useEffect(() => {
    setIsPasswordValid(validators.filter((validator) => validator.enabled).every((validator) => validator.status));
  }, [password, passwordConfirmation]);

  return (
    <section className={`password-validator-list-container`}>
      {validators.map((validator, index) => {
        if (validator.enabled) {
          return (
            <div
              key={validator.title}
              id={`text-signup-rule-${index}`}
              className={`password-validator-item ${validator.status ? "step-is-valid" : ""}`}
            >
              <CheckCircle style={{ fontSize: 16 }} />
              <span className={`validator-item-text`}>{validator.title}</span>
            </div>
          );
        }
      })}
    </section>
  );
}

export default memo(PasswordValidatorSteps);
